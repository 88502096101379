import request from '@/core/services/api.services.request'

export function getAllSuppliers(params) {
  return request({
    url: '/supplier/list',
    method: 'get',
    params: params
  })
}

export function getSupplierDetails(supplier_id) {
  return request({
    url: '/supplier/view/'+supplier_id,
    method: 'get'
  })
}

export function deleteSupplier(supplier_id) {
  return request({
    url: '/supplier/delete/'+supplier_id,
    method: 'delete'
  })
}

export function getAllSuppliersList() {
  return request({
    url: '/supplier/activelist',
    method: 'get'
  })
}

export function getAllSupplierPOList(supplier_id) {
  return request({
    url: '/supplier/'+supplier_id+'/po/list',
    method: 'get'
  })
}


export function changeStatus(supplier_id, status) {
  return request({
    url: '/supplier/change/status/' + supplier_id + '/' + status,
    method: 'post',
  })
}

export function searchSupplier(keyword) {
  return request({
    url: '/purchase-order/search/supplier/' + keyword,
    method: 'get',
  })
}

export function getPuchaseOrder(po_id) {
  return request({
    url: '/purchase-order/view/'+po_id,
    method: 'get',
  })
}

export function getSupplierTerms(supplier_id) {
  return request({
    url: '/supplier/term/list/'+supplier_id,
    method: 'get',
  })
}

export function getPurchaseOrder(params) {
  return request({
    url: '/purchase-order/list',
    method: 'get',
    params: params
  })
}
export function getAllPurchaseOrder(params) {
  return request({
    url: 'all/purchase-order/list',
    method: 'get',
    params: params
  })
}
export function getCompletePurchaseOrder(params) {
  return request({
    url: 'complete/purchase-order/list',
    method: 'get',
    params: params
  })
}

export function viewPurchaseOrder(po_id) {
  return request({
    url: '/purchase-order/view/'+po_id,
    method: 'get',
  })
}

export function getProductList(supplier_id,params){
  return request({
    url: '/supplier/product/'+supplier_id,
    method: 'get',
    params: params
  })
}

export function generateSupplierId(){
  return request({
    url: '/supplier/generate-supplier-id',
    method: 'get',
  })
}

export function getLowstockProductList(supplier_id){
  return request({
    url: '/supplier/lowstock/product/'+supplier_id,
    method: 'get',
  })
}

export function getBackOrderProductList(supplier_id){
  return request({
    url: '/supplier/backorder/product/'+supplier_id,
    method: 'get',
  })
}

export function searchProduct(keyword,products) {
  return request({
    url: '/order/search/product/' + keyword,
    params: products,
    method: 'get',
  })
}

export function getSupplierProductList(supplier_id,params){
  return request({
    url: '/supplier/productList/'+supplier_id,
    method: 'get',
    params: params
  })
}

export function getDropDownSupplier(){
  return request({
    url: '/supplier/list/dropdown',
    method: 'get',
  })
}

export function getAllSuppliersListEditProduct() {
  return request({
    url: '/supplier/activelist/EditProduct',
    method: 'get'
  })
}


export function getSupplierAllProductList(supplier_id,params){
  return request({
    url: '/supplier/allproductList/'+supplier_id,
    method: 'get',
    params: params
  })
}
