<template>
<div>
  <div slot="header" class="modal-header popup-header">
    <span class="modal-title-popup"><i class="flaticon-interface-9" aria-hidden="true"></i>{{label.title}}</span>
			<i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
		</div>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Employees-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Employees Body-->
                <div class="row justify-content-center ">
                    <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                         <el-form ref="form" :model="form" auto-complete="nope">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="row">
                                            <div class="col-xl-8">
                                               <el-form-item label="Term Name" prop="term_name">
                                                 <el-input v-model="form.term_name" @input.native="capitalize" auto-complete="nope" :class="form.errors.has('term_name') ? 'error required':'required'"></el-input>
                                                 <span class="form-err" v-if="form.errors.has('term_name')" >{{form.errors.get('term_name')}}</span>
                                              </el-form-item>                                                   
                                            </div>                                         
                                        </div>
                                    </div>                                   
                                </div>
                            </div>

                            <!--begin: Employees Actions -->
                            <div class="d-flex justify-content-between border-top pt-10">
                                <a  @click="createTerm" class="text-white">
                                    <div class="btn btn-primary font-weight-bold text-uppercase">
                                        {{submit_btn_text}}
                                    </div>
                                </a>
                            </div>
                            <!--end: Employees Actions -->
                        </el-form>
                        <!--end: Employees Form-->
                    </div>
                </div>
                <!--end: Employees Body-->
            </div>
        </div>
        <!--end: Employees-->
    </div>
</div>
</template>

<script>
import Form from '@/core/services/api.form.services'
import {globalEvents} from '@/utils/globalEvents';
export default {
  name: 'create-term',
  props: {
    supplier_id: {
      type: Number,
      required: true
    },
    label:{
      type: Object,
      default() {
        return {button:'Create',title:'Create Term'}
      }
	  }
  },
  data() {
    return {
      loading : false,
      error:null,
      form : new Form({
        supplier_id : null,
        term_name : null
      }),
       
       submit_btn_text: 'Add Term',
    }
  },
  methods: {
    reset() {
      this.form.reset()
      this.$refs['form'].resetFields()
    },
    closeForm() {
      if (!this.form.submiting) {
        this.$emit('close')
      }
    },
    createTerm() {      
      this.loading=true;
      const formURL = 'supplier/term/create'
      //const msg = 'The term has been created'

      this.form.post(formURL).then((response) => {
        //this.$showResponse('success', msg);
        this.loading=false;
        globalEvents.$emit('termAdded', response.data)
        this.$emit('close')
      }).catch((error) => {
        console.log(error);
      })
    }
  },
  created() {
  },
    mounted() {
        if(this.supplier_id){
            this.form.supplier_id = this.supplier_id
        }
    }
}

</script>

<style scoped>
.option-info{
  float: left; color: #8492a6; font-size: 13px; clear:both;line-height: 20px;
}
.option-info i {
  color: #27304A;
}
.title{
  font-size: 16px;
  font-weight: 600;
  color: #27304A;
  display: inline-block;
  margin-bottom: 20px;
}
.event-container {
  background-color: white;
  padding: 20px;
  border: 1px solid #ebeef5;
}
.icon-pointer{
  font-size: 20px; cursor: pointer;color: #616e8e;
}
</style>
