<!-- Supplier -->
<template>
  <div class="card card-custom product_ct" v-loading="loading">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-9">
          <div class="d-flex mb-9">
            <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
              <div class="symbol symbol-50 symbol-lg-120">
                <span
                  class="index-image employee-image"
                  v-loadimage="view.supplier_image"
                ></span>
              </div>
            </div>
            <div class="flex-grow-1">
              <div class="d-flex justify-content-between flex-wrap mt-1">
                <div class="d-flex mr-3">
                  <span
                    class="
                      text-dark-75 text-hover-primary
                      font-size-h5 font-weight-bold
                      mr-3
                    "
                    >{{
                      view.company_name
                        ? view.company_name
                        : view.first_name +
                          " " +
                          (view.last_name != null ? view.last_name : "")
                    }}</span
                  >
                  <span v-if="view.active === 1"
                    ><i class="flaticon2-correct text-success font-size-h5"></i
                  ></span>
                </div>
              </div>
              <div class="d-flex flex-wrap justify-content-between mt-1">
                <div class="d-flex flex-column flex-grow-1 pr-8">
                  <div v-if="view.email_address" class="d-flex flex-wrap mb-4">
                    <span
                      class="
                        text-dark
                        font-weight-bold
                        mr-lg-8 mr-5
                        mb-lg-0 mb-2
                      "
                      ><i class="flaticon-rotate mr-2 font-size-lg"></i>Supplier
                      ID : <b>{{ view.supplier_Id }}</b></span
                    >
                  </div>
                  <div v-if="view.email_address" class="d-flex flex-wrap mb-4">
                    <span
                      class="
                        text-dark
                        font-weight-bold
                        mr-lg-8 mr-5
                        mb-lg-0 mb-2
                      "
                      ><i class="flaticon2-new-email mr-2 font-size-lg"></i
                      >{{ view.email_address }}</span
                    >
                  </div>
                  <div
                    v-if="view.phone || view.mobile"
                    class="d-flex flex-wrap mb-4"
                  >
                    <span
                      class="
                        text-dark
                        font-weight-bold
                        mr-lg-8 mr-5
                        mb-lg-0 mb-2
                      "
                      ><i class="flaticon2-phone mr-2 font-size-lg"></i
                      ><span v-if="view.phone">{{ view.phone }},</span>
                      <span v-if="view.mobile">{{ view.mobile }}</span></span
                    >
                  </div>
                  <div v-if="view.fax" class="d-flex flex-wrap mb-4">
                    <span class="text-dark font-weight-bold"
                      ><i class="flaticon2-fax mr-2 font-size-lg"></i
                      >{{ view.fax }}</span
                    >
                  </div>
                  <div
                    v-if="
                      view.unit_no ||
                      view.street ||
                      view.state ||
                      view.city ||
                      view.postal_code
                    "
                    class="d-flex flex-wrap mb-4"
                  >
                    <span class="text-dark font-weight-bold">
                      <i class="flaticon2-placeholder mr-2 font-size-lg"></i>
                      {{ view.street ? view.street + ", " : "" }}
                      {{
                        view.unit_no ? "Unit No : " + view.unit_no + ", " : ""
                      }}
                      {{
                        view.state && view.state.state_name
                          ? view.state.state_name
                          : ""
                      }}
                      {{
                        view.city && view.city.city_name
                          ? ", " + view.city.city_name
                          : ""
                      }}
                      {{
                        view.postal_code ? " - " + view.postal_code : ""
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="text-right">
            <b-button
              class="
                rounded-0
                btn btn-sm btn-primary
                font-weight-bold
                text-uppercase
              "
              variant="primary"
              @click="editSupplier(view.id)"
              ><i class="fa fa-pen"></i>Edit
            </b-button>
          </div>
        </div>
      </div>
      <el-form>
        <hr />
        <div class="row">
          <div class="col-sm-5">
            <div class="row">
              <div class="col-sm-6">
                <div class="profile-attr">
                  <label><i class="flaticon2-world"></i>Website</label>
                  <p>{{ view.website }}</p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="profile-attr">
                  <label><i class="flaticon-buildings"></i>Company</label>
                  <p>{{ view.company_name ? view.company_name : "-" }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-7">
            <div class="row">
              <div class="col-sm-4">
                <div class="profile-attr">
                  <label><i class="flaticon-squares-1"></i>Category</label>
                  <p v-if="view.category">{{ view.category.label }}</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="profile-attr">
                  <label
                    ><i class="flaticon-calendar-with-a-clock-time-tools"></i
                    >Created Date</label
                  >
                  <p>{{ view.created_at | formatDate("DD MMM YYYY") }}</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="profile-attr">
                  <label
                    ><i class="flaticon-safe-shield-protection"></i
                    >Status</label
                  >
                  <p v-if="view.active === 1" class="active">Active</p>
                  <p v-else class="red">In Active</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <hr />
        <div class="row">
          <div class="col-sm-5">
            <div class="row">
              <div class="col-sm-6">
                <div class="profile-attr">
                  <label
                    ><i class="flaticon2-percentage"></i>Regular
                    Percentage</label
                  >
                  <p v-if="view.regular_percentage">
                    {{ view.regular_percentage }} %
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="profile-attr">
                  <label
                    ><i class="flaticon2-percentage"></i>Contractor Off</label
                  >
                  <p v-if="view.contractor_percentage">
                    {{ view.contractor_percentage }} %
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-7">
            <div class="row">
              <div class="col-sm-4">
                <div class="profile-attr">
                  <label
                    ><i class="flaticon2-percentage"></i>Dealer Margin</label
                  >
                  <p v-if="view.dealer_percentage">
                    {{ view.dealer_percentage }} %
                  </p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="profile-attr">
                  <label
                    ><i class="flaticon2-percentage"></i>Wholesale Off</label
                  >
                  <p v-if="view.wholesale_percentage">
                    {{ view.wholesale_percentage }} %
                  </p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="profile-attr">
                  <label
                    ><i class="flaticon2-percentage"></i>Inside
                    Percentage</label
                  >
                  <p v-if="view.inside_percentage">
                    {{ view.inside_percentage }} %
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-12">
                <h4>Product Details:</h4>
              </div>
            </div>

            <div class="row mt-10">
              <div class="col-sm-12">
                <template>
                  <el-tabs
                    class="root-tab"
                    type="card"
                    v-model="activeTab"
                    @tab-click="handleClick"
                  >
                    <el-tab-pane label="Products" name="product">
                      <div class="row">
                        <div class="col-sm-5 col-xs-12">
                          <b-form-group class="mb-3">
                            <b-input-group size="sm">
                              <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Type to Search"
                                v-on:keyup.enter="fetchData(1)"
                                v-on:keydown="fetchData(1)"
                                auto-complete="nope"
                              ></b-form-input>
                              <!-- v-on:keyup.enter="fetchData(1)" v-on:keydown="fetchData(1)" -->
                              <span
                                class="search-icon"
                                v-if="filter"
                                id="clear-button"
                              >
                                <i
                                  class="fas fa-times"
                                  @click="filter = ''"
                                ></i>
                                <b-tooltip
                                  target="clear-button"
                                  variant="primary"
                                  >Clear</b-tooltip
                                >
                              </span>
                              <b-input-group-append>
                                <b-button
                                  :disabled="!filter"
                                  @click="filter ? fetchData(1) : ''"
                                  variant="primary"
                                  >Search</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </div>
                        <div class="col-sm-7 col-xs-12 text-right">
                          <b-button
                            class="
                              mb-5
                              rounded-3
                              btn btn-sm btn-primary
                              font-weight-bold
                              text-uppercase
                            "
                            variant="primary"
                            @click="createProduct"
                            ><i class="fa fa-plus"></i>Add Product
                          </b-button>
                        </div>
                      </div>
                      <el-table
                        :data="product_list"
                        border
                        element-loading-text="Loading"
                        width="70%"
                      >
                        <el-table-column
                          label="SI NO"
                          width="60"
                          sortable
                          align="center"
                          type="index"
                        >
                          <template v-slot="{ $index }">
                            <span>{{
                              $index +
                              pagination.per_page *
                                (pagination.current_page - 1) +
                              1
                            }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Supplier SKU"
                          width="120"
                          sortable
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{
                              scope.row.mfg_id ? scope.row.mfg_id : ""
                            }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="Product Name" width="200px">
                          <template slot-scope="scope">
                            <span
                              >{{
                                scope.row.products
                                  ? scope.row.products.name
                                  : ""
                              }}
                              - <b>{{ scope.row.products.sku }}</b></span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column label="UPC Code" width="110px">
                          <template slot-scope="scope">
                            <span>{{
                              scope.row.upc_code ? scope.row.upc_code : ""
                            }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Cost Price($)"
                          width="110"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span v-if="scope.row.supplier_cost"
                              >${{
                                parseFloat(scope.row.supplier_cost).toFixed(2)
                              }}</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Regular Price($)"
                          width="130"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span v-if="scope.row.products.regular_price"
                              >${{
                                parseFloat(
                                  scope.row.products.regular_price
                                ).toFixed(2)
                              }}</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Wholesale Price($)"
                          width="150"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span v-if="scope.row.products.whole_amount"
                              >${{
                                parseFloat(
                                  scope.row.products.whole_amount
                                ).toFixed(2)
                              }}</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Contractor Price($)"
                          width="150"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span v-if="scope.row.products.contractor_price"
                              >${{
                                parseFloat(
                                  scope.row.products.contractor_price
                                ).toFixed(2)
                              }}</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Dealer Price($)"
                          width="150"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span v-if="scope.row.products.dealer_amount"
                              >${{
                                parseFloat(
                                  scope.row.products.dealer_amount
                                ).toFixed(2)
                              }}</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          fixed="right"
                          align="center"
                          label="Actions"
                          width="80"
                        >
                          <template slot-scope="scope">
                            <el-dropdown
                              @command="handleActions($event, scope.row)"
                              size="medium"
                            >
                              <i class="flaticon2-menu-1"></i>
                              <el-dropdown-menu
                                slot="dropdown"
                                class="action-dropdown"
                              >
                                <el-dropdown-item :command="1"
                                  ><i class="fa fa-eye"></i
                                  >View</el-dropdown-item
                                >
                                <el-dropdown-item :command="2"
                                  ><i class="fa fa-plus"></i>Create Purchase
                                  Order</el-dropdown-item
                                >
                              </el-dropdown-menu>
                            </el-dropdown>
                          </template>
                        </el-table-column>
                      </el-table>
                      <div class="row">
                        <div class="col-sm-12">
                          <el-pagination
                            v-if="!loading"
                            style="margin-top: 15px"
                            background
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="handleSizeChange"
                            @current-change="fetchData"
                            :page-count="pagination.last_page"
                            :page-sizes="[10, 25, 50, 100]"
                            :page-size="pagination.per_page"
                            :current-page.sync="pagination.current_page"
                            :total="pagination.total_rows"
                          >
                          </el-pagination>
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="Purchase Order" name="purchase">
                      <el-table
                        :data="purchase_order"
                        border
                        element-loading-text="Loading"
                        style="width: 100%"
                        v-loading="loading"
                      >
                        <el-table-column
                          fixed
                          align="center"
                          label="PO Number"
                          prop="po_number"
                          sortable
                          width="120"
                        >
                          <template slot-scope="scope">
                            <el-tag slot="reference" size="mini"
                              ><a
                                href="#"
                                @click.prevent="viewPurchase(scope.row.id)"
                                >{{
                                  scope.row.po_number ? scope.row.po_number : ""
                                }}</a
                              ></el-tag
                            >
                          </template>
                        </el-table-column>
                        <el-table-column label="Supplier" :min-width="200">
                          <template slot-scope="scope">
                            <span>{{
                              scope.row.supplier
                                ? scope.row.supplier.company_name
                                : ""
                            }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Request Date"
                          prop="delivery_date"
                          sortable
                          width="190"
                        >
                          <template slot-scope="scope">
                            {{
                              scope.row.request_date | formatDate("DD MMM YYYY")
                            }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="'Confirm By"
                          sortable
                          width="200"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.confirm_by }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Order Status"
                          sortable
                          width="200"
                        >
                          <template slot-scope="scope">
                            <span
                              v-if="scope.row.order_status == 'pending'"
                              class="gray"
                              >Pending Payment</span
                            >
                            <span
                              v-if="scope.row.order_status == 'processing'"
                              class="active"
                              >Processing</span
                            >
                            <span
                              v-if="scope.row.order_status == 'on-hold'"
                              class="gray"
                              >On Hold</span
                            >
                            <span
                              v-if="scope.row.order_status == 'completed'"
                              class="active"
                              >Completed</span
                            >
                            <span
                              v-if="scope.row.order_status == 'cancelled'"
                              class="gray"
                              >Cancelled</span
                            >
                            <span
                              v-if="scope.row.order_status == 'refunded'"
                              class="red"
                              >Refunded</span
                            >
                            <span
                              v-if="scope.row.order_status == 'failed'"
                              class="red"
                              >Failed</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          fixed="right"
                          align="center"
                          label="Actions"
                          width="80"
                        >
                          <template slot-scope="scope">
                            <el-dropdown
                              @command="handleActions1($event, scope.row)"
                              size="medium"
                            >
                              <i class="flaticon2-menu-1"></i>
                              <el-dropdown-menu
                                slot="dropdown"
                                class="action-dropdown"
                              >
                                <el-dropdown-item :command="1"
                                  ><i class="fa fa-eye"></i
                                  >View</el-dropdown-item
                                >
                              </el-dropdown-menu>
                            </el-dropdown>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Low Stock Products" name="lowstock">
                      <el-table
                        :data="lowstock_list"
                        border
                        element-loading-text="Loading"
                        width="70%"
                      >
                        <el-table-column
                          label="Product ID"
                          sortable
                          width="120px"
                        >
                          <template slot-scope="scope">
                            <span>{{
                              scope.row.products ? scope.row.products.sku : ""
                            }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Product Name"
                          sortable
                          width="200px"
                        >
                          <template slot-scope="scope">
                            <span
                              >{{
                                scope.row.products
                                  ? scope.row.products.name
                                  : ""
                              }}
                              - <b>{{ scope.row.products.sku }}</b></span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Stock Quantity"
                          width="200"
                          sortable
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{
                              scope.row.products
                                ? scope.row.products.stock_quantity
                                : ""
                            }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Max Quantity"
                          width="200"
                          sortable
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{
                              scope.row.products
                                ? scope.row.products.max_quantity
                                : ""
                            }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Min Quantity"
                          width="200"
                          sortable
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{
                              scope.row.products
                                ? scope.row.products.min_quantity
                                : ""
                            }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Reorder"
                          width="150"
                          sortable
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{
                              scope.row.products
                                ? scope.row.products.max_quantity -
                                  scope.row.products.stock_quantity
                                : ""
                            }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Stock Status"
                          width="200"
                          sortable
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span v-if="scope.row.products" class="red"
                              >Low Stock</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          fixed="right"
                          align="center"
                          label="Actions"
                          width="80"
                        >
                          <template slot-scope="scope">
                            <el-dropdown
                              @command="handleActions($event, scope.row)"
                              size="medium"
                              v-if="scope.row.products"
                            >
                              <i class="flaticon2-menu-1"></i>
                              <el-dropdown-menu
                                slot="dropdown"
                                class="action-dropdown"
                              >
                                <el-dropdown-item :command="1"
                                  ><i class="fa fa-eye"></i
                                  >View</el-dropdown-item
                                >
                                <el-dropdown-item :command="2"
                                  ><i class="fa fa-plus"></i>Create Purchase
                                  Order</el-dropdown-item
                                >
                              </el-dropdown-menu>
                            </el-dropdown>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Back Order Products" name="backorder">
                      <el-table
                        :data="backorder_list"
                        border
                        element-loading-text="Loading"
                        width="70%"
                      >
                        <el-table-column
                          fixed
                          align="center"
                          label="PO Back Order #"
                          prop="po_id"
                          sortable
                          width="160"
                        >
                          <template slot-scope="scope">
                            <el-tag slot="reference" size="mini"
                              ><a
                                href="#"
                                @click.prevent="
                                  viewPurchaseBackOrder(
                                    scope.row.po_id,
                                    scope.row.order_status,
                                    scope.row.id
                                  )
                                "
                                >{{
                                  scope.row.back_order_number
                                    ? scope.row.back_order_number
                                    : ""
                                }}</a
                              ></el-tag
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          fixed
                          align="center"
                          label="PO Number"
                          prop="po_number"
                          sortable
                          width="120"
                        >
                          <template slot-scope="scope">
                            {{ scope.row.po_number ? scope.row.po_number : "" }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Created By"
                          sortable
                          width="200"
                        >
                          <template slot-scope="scope">
                            <span>{{ scope.row.created_by }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Created Date"
                          prop="created_at"
                          sortable
                          width="190"
                        >
                          <template slot-scope="scope">
                            {{
                              scope.row.created_at | formatDate("DD MMM YYYY")
                            }}
                          </template>
                        </el-table-column>

                        <el-table-column
                          label="Order Status"
                          sortable
                          width="200"
                        >
                          <template slot-scope="scope">
                            <span
                              v-if="scope.row.order_status == 'processing'"
                              class="active"
                              >Processing</span
                            >
                            <span
                              v-if="scope.row.order_status == 'on-hold'"
                              class="gray"
                              >On Hold</span
                            >
                            <span
                              v-if="scope.row.order_status == 'completed'"
                              class="active"
                              >Completed</span
                            >
                            <span
                              v-if="scope.row.order_status == 'cancelled'"
                              class="gray"
                              >Cancelled</span
                            >
                            <span
                              v-if="scope.row.order_status == 'partial_process'"
                              class="red"
                              >Partial Received</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          fixed="right"
                          align="center"
                          label="Actions"
                          width="80"
                        >
                          <template slot-scope="scope">
                            <el-dropdown
                              @command="
                                handleActionsbackorders($event, scope.row)
                              "
                              size="medium"
                            >
                              <i class="flaticon2-menu-1"></i>
                              <el-dropdown-menu
                                slot="dropdown"
                                class="action-dropdown"
                              >
                                <el-dropdown-item
                                  :command="1"
                                  v-if="scope.row.order_status == 'completed'"
                                  ><i class="fa fa-eye"></i
                                  >View</el-dropdown-item
                                >
                                <el-dropdown-item
                                  :command="2"
                                  v-if="
                                    scope.row.order_status == 'partial_process'
                                  "
                                  ><i class="fa fa-pen"></i>Received
                                  PO</el-dropdown-item
                                >
                              </el-dropdown-menu>
                            </el-dropdown>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                  </el-tabs>
                </template>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<style>
.settingicon {
  font-size: 20px;
}
h2.edit {
  text-align: right;
}
h2.edit a {
  cursor: pointer;
}
.title-width div label {
  width: 35%;
}
.title-width,
.title-name {
  padding-top: 30px;
}
.employee-image img {
  width: 125px;
  border: 1px solid #ec2223;
  padding: 5px;
  border-radius: 5px;
  height: 125px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  getSupplierDetails,
  getAllSupplierPOList,
  getSupplierAllProductList,
  getLowstockProductList,
  getBackOrderProductList,
} from "@/api/supplier";
import createProduct from "@/components/CreateProduct/add.vue";
import { globalEvents } from "@/utils/globalEvents";
import _ from "lodash";
export default {
  name: "view-supplier",
  components: {},
  data() {
    return {
      loading: false,
      view: [],
      filter: null,
      purchase_order: [],
      product_list: [],
      lowstock_list: [],
      backorder_list: [],
      activeTab: "product",
      pagination: {
        total_rows: 1,
        current_page: 1,
        last_page: null,
        per_page: 10,
      },
      fields: [
        {
          key: "name",
          label: "Product Name",
          sortable: true,
          class: "m-w-name",
        },
        { key: "sku", label: "SKU", sortable: true, class: "text-center" },
        {
          key: "cost_price",
          label: "Cost Price",
          sortable: true,
          class: "text-center",
        },
        {
          key: "wholesale_price",
          label: "Wholesale Price",
          sortable: true,
          class: "text-center",
        },
        { key: "actions", label: "" },
      ],
      category_id: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Suppliers", route: "/supplier/list" },
      { title: "View Supplier" },
    ]);
    globalEvents.$on("productAdd", (addproduct) => {
      if (addproduct) {
        this.fetchData(1);
      }
    });
  },
  created() {
    this.fetchData(1);
  },
  watch: {
    filter: _.debounce(function (n) {
      this.loading = true;
      if (!n) {
        this.fetchData(1);
        this.loading = false;
      }
    }, 200),
  },
  methods: {
    fetchData(p) {
      this.loading = true;
      let params = { page: p };
      if (this.filter) {
        params.filter = this.filter;
      }
      if (this.sort) {
        params.sort = this.sort;
      }
      if (this.pagination.per_page) {
        params.per_page = this.pagination.per_page;
      }
      getSupplierDetails(this.$route.params.supplierId).then((response) => {
        this.view = response.data.data;
        this.category_id = response.data.data.category_id;
        this.loading = false;
      });
      getAllSupplierPOList(this.$route.params.supplierId).then((response) => {
        this.purchase_order = response.data.data;
        this.loading = false;
      });

      getSupplierAllProductList(this.$route.params.supplierId, params).then(
        (response) => {
          this.product_list = response.data.data.data;
          this.pagination.current_page = response.data.data.current_page;
          this.pagination.last_page = response.data.data.last_page;
          this.pagination.total_rows = response.data.data.total;

          this.$set(this.product_list, "_showDetails", true);
          this.loading = false;
        }
      );
      getLowstockProductList(this.$route.params.supplierId).then((response) => {
        this.lowstock_list = response.data.data;
        this.loading = false;
      });
      getBackOrderProductList(this.$route.params.supplierId).then(
        (response) => {
          this.backorder_list = response.data.data;
          console.log(this.backorder_list);
          this.loading = false;
        }
      );
    },

    handleActions(evt, row) {
      if (evt === 1) {
        var supplier_id = row.supplier;
        var product_id = parseInt(row.products.id);
        this.$router.push({
          name: "supplier_product_view",
          params: { supplier_id: supplier_id, product_id: product_id },
        });
      } else if (evt === 2) {
        const supplier_id = row.supplier;
        this.$router.push({
          name: "create-purchase-order",
          params: { supplier_id: supplier_id },
          query: { productId: row.product_id, supplierId: supplier_id },
        });
      }
    },

    handleSizeChange(per_page) {
      this.pagination.per_page = per_page;
      this.fetchData(1);
    },
    editSupplier(supplier_id) {
      this.$router.push({ path: "/supplier/edit/" + supplier_id });
    },
    handleActions1(evt, row) {
      if (evt === 1) {
        this.$router.push({ path: "/purchase-order/view/" + row.id });
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    viewPurchase(purchase_id) {
      this.$router.push({ path: "/purchase-order/view/" + purchase_id });
    },
    handleActionsbackorders(evt, row) {
      if (evt === 1) {
        this.$router.push({ path: "/back-purchase-order/view/" + row.id });
      } else if (evt === 2) {
        this.$router.push({ path: "/add/recive/purchase/order/" + row.po_id });
      }
    },
    viewPurchaseBackOrder(purchase_id, status, backorder_id) {
      if (status == "partial_process")
        this.$router.push({
          path: "/add/recive/purchase/order/" + purchase_id,
        });
      else
        this.$router.push({
          path: "/back-purchase-order/view/" + backorder_id,
        });
    },
    createProduct() {
      console.log(this.category_id, "category");
      this.$modal.show(
        createProduct,
        {
          supplier_id: parseInt(this.$route.params.supplierId),
          category_id: this.category_id,
          //customer_id: row.customer_address[0].customer_id
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "inherit",
          clickToClose: false,
          adaptive: true,
          width: "1000px",
        }
      );
    },
  },
};
</script>

<style scoped>
</style>